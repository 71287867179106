.no-shrink {
  flex-shrink: 0;
}

.flex-grow {
  flex-grow: 1;
}

.align-center {
  align-items: center;
}

.justify-center {
  justify-content: center;
}

.flex-wrap {
  flex-wrap: wrap;
}

.fit-content {
  width: fit-content;
}

.no-select {
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.no-gap {
  gap: 0px;
}

.small-gap {
  gap: 3px;
}

.no-min {
  min-height: 0px;
  min-width: 0px;
}

.full-page {
  width: 100vw;
  height: 100vh;
}

::-webkit-scrollbar {
  width: 4px;
}

::-webkit-scrollbar-track {
  background: #f1f1f1;
}

::-webkit-scrollbar-thumb {
  background: #888;
}

::-webkit-scrollbar-thumb:hover {
  background: #555;
}

.dawn-fullscreen {
  position: absolute;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  overflow: hidden;
  background-color: #00000044;
  z-index: 10;
}

.dawn-page-center {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.dawn-round {
  border-radius: 100px;
}

.clickable:hover {
  cursor: pointer;
}

.round {
  border-radius: 100px;
}

.lift-up {
  position: relative;
}

.lift-up:hover {
  animation: ease-up 0.1s linear forwards;
}

@keyframes ease-up {
  0% {
    top: 0px;
  }

  100% {
    top: -10px;
  }
}

body:has(#root .dawn-fullscreen) {
  overflow: hidden;
}

.dawn-color-danger {
  color: var(--dawn-control-hover-danger);
}

.dawn-highlight {
  background-color: var(--dawn-accent);
  color: var(--dawn-accent-text);
}

.dawn-hoverable {
  border-radius: var(--dawn-border-radius);
  transition: var(--dawn-hover-transition);
}

.dawn-hoverable,
.dawn-hoverable > * > * {
  cursor: pointer;
}

.dawn-hoverable:hover {
  background-color: var(--dawn-control-hover-background) !important;
}

.dawn-clickable {
  cursor: pointer;
}

.dawn-selected,
.selected {
  background-color: var(--dawn-control-hover-background);
}

.dawn-hover-strike:hover {
  text-decoration: line-through;
}