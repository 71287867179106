:root {
  /* ----- Colors ----- */
  --dawn-root-background: #222;
  --dawn-root-color: #fff;

  --dawn-control-background: #333;
  --dawn-control-hover-background: #555;
  --dawn-control-border: #444;

  --dawn-input: #3c3c3c;
  --dawn-input-hover: #444;
  --dawn-input-focus: #555;

  --dawn-control-background-2: #2a2a2a;

  --dawn-popup-background: #111;

  --dawn-control-danger: #770000;
  --dawn-control-text-danger: white;
  --dawn-control-hover-danger: #cc0000;
  --dawn-text-danger: #ff0000;

  --dawn-control-success: #00a800;
  --dawn-control-text-success: black;
  --dawn-control-hover-success: #00ff00;
  --dawn-text-success: #00ff00;

  --dawn-control-link: #18e6f5;

  --dawn-accent: #c3b1e1;
  --dawn-accent-text: white;

  /* ----- Values ----- */
  --dawn-hover-transition: 0.2s;
  --dawn-border-radius: 20px;
  --dawn-control-border-radius: 5px;
  --dawn-flex-gap: 20px;
  --dawn-page-width: 60%;
}

body {
  background-size: cover;
  background-repeat: no-repeat;
  background-attachment: fixed;

  font-family: Arial, Helvetica, sans-serif;

  margin: 0;

  background-color: var(--dawn-root-background);
  color: var(--dawn-root-color);
}

hr {
  width: 90%;
  border-style: solid;
  border-color: var(--dawn-control-border);
}

label {
  word-wrap: break-word;
}

/* ----- Utils ----- */
.dawn-big {
  box-sizing: border-box;
  width: 100%;
  font-size: 1.2em;
  padding: 10px;
  margin: 10px 0px;
  display: block;
  text-align: center;
}
