.dawn-visual-text {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 1000;
  font-size: 7em;
  opacity: 0.5;
}

.quote-text {
  position: absolute;
  bottom: -10px;
  right: -30px;
  max-width: 200px;
  transform: rotate(-30deg);
  text-align: center;
  color: var(--dawn-accent);
  animation: quote linear infinite 0.3s alternate;
}

@keyframes quote {
  0% {
    transform: scale(1) rotate(-30deg);
  }

  100% {
    transform: scale(1.2) rotate(-30deg);
  }
}

@media screen and (max-width: 700px) {
  .quote-text {
    bottom: -100px;
    right: 50%;
    transform: translate(50%);
  }

  @keyframes quote {
    0% {
      transform: scale(1) translate(50%);
    }

    100% {
      transform: scale(1.2) translate(50%);
    }
  }
}
