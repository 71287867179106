@media only screen and (max-width: 700px) {
  .dawn-page-content,
  .dawn-content,
  .dawn-row,
  .dawn-row > a,
  .dawn-container,
  .dawn-navbar-page-align {
    margin-left: 0px;
    margin-right: 0px;
    width: 100% !important;
    flex-wrap: wrap;
  }

  .dawn-banner-image {
    display: none;
  }

  .dawn-row > a {
    display: block;
  }

  .dawn-navbar-content {
    flex-direction: column;
  }

  .dawn-navbar-content > .dawn-row {
    display: block;
    text-align: center;
  }

  .dawn-navbar-content > .dawn-row > a {
    display: inline;
    width: fit-content;
    margin: 5px;
  }

  .dawn-page-center {
    width: 100vw;
  }

  .dawn-alert {
    width: 100vw !important;
    max-width: 100vw;
    min-width: 0;
  }
}
