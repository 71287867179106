.dawn-column {
  display: flex;
  flex-direction: column;
  gap: var(--dawn-flex-gap);
}

.dawn-row {
  width: 100%;
  display: flex;
  flex-direction: row;
  gap: var(--dawn-flex-gap);
}
